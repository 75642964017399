<template>
    <div class="associates__list">
        <table class="table" v-if="associates.length">
            <thead class="table__table-head">
                <th>Name</th>
                <th>Phone</th>
                <th>Date of Birth</th>
                <th>Options</th>
            </thead>
            <tbody>
                <tr v-for="associate in associates">
                    <td class="associates__name">
                        <img src="@/assets/images/user-profile.svg" class="associates__image" width="30" height="30"
                            alt="" v-if="!associate.associateImage">

                        <img :src="filesPath + associate.associateImage" class="associates__image" width="30"
                            height="30" alt="" v-else>

                        <span>{{ associate.associateName }}</span>
                    </td>
                    <td>{{ associate.associatePhone }}</td>
                    <td>{{ associate.associateDob }}</td>
                    <td>
                        <div class="table__options">
                            <router-link title="Edit Associate" :to="`/associates/edit/${associate.id}`">
                                <img src="@/assets/icons/vuesax/linear/edit.svg" width="15" height="15" />
                            </router-link>

                            <a href="#" title="Delete Associate" @click.prevent="showModal(associate.id)">
                                <img src="@/assets/icons/vuesax/linear/trash.svg" width="15" height="15" />
                            </a>

                            <a href="#" title="Rooms Logs" @click.prevent="userId = associate.userId">
                                <img src="@/assets/icons/vuesax/linear/note.svg" width="15" height="15" />
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Table Paginator Component -->
    <TablePaginator ref="paginator" :api-url="associatesListApi" :filter="filterWords" v-model="associates" />

    <!-- Modal Delete Component -->
    <ModalDelete ref="modalDelete" @on-delete="refreshList" />

    <!-- Associates Logs Component -->
    <AssociatesLogsModal :associate-id="userId" @close-modal="userId = null" />

    <!-- Empty table records component -->
    <TableEmptyData title="Associates List Empty" message="We did not find associates in your list..."
        v-if="!associates.length" />

</template>

<script setup>
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'
import ModalDelete from '@/common/ModalDelete.vue'
import AssociatesLogsModal from './AssociatesLogsModal.vue'
import { ref, watch } from 'vue'

const props = defineProps(['filterWords', 'refreshTable'])
const paginator = ref(null)
const associatesListApi = `/web/associates`
const filesPath = process.env.VUE_APP_FILES_PATH
const userId = ref(null)
let modalDelete = ref(null)
let associates = ref([])

watch(() => props.refreshTable, (value) => {
    if (value) refreshList()
})

function showModal(associateId) {
    const deleteAssociateApi = `/web/associates/delete/${associateId}`
    modalDelete.value.showModal(deleteAssociateApi)
}

function refreshList() {
    paginator.value.refreshPage()
}
</script>